.discord-box {
  color: white;
  background-color: #222222;
  height: 528px;
  width: 350px;
  min-width: 350px;
  margin-top: 20px;
  margin-left: 22px;
  border: #69503b 3px double;
  border-radius: 8px;
  position: absolute;
}
