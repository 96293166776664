.App-footer {
  width: 100%;
  background-image: url('./Footer_images/bg_footer.jpg');
  background-size: 2000px 250px;
  background-repeat: repeat-x;
  height: 250px;
  color: white;
  text-align: left;
}

.footer-links {
  display: inline-flex;
  margin-top: 115px;
  margin-left: 5%;
}

.footer-link-item {
  margin-left: 50px;
  margin-right: 100px;
}

.footer-link-img {
  width: 50px;
  height: 50px;
}
.footer-link-img:hover {
  border: 4px solid #00000000;
  width: 46px;
  height: 46px;
  margin-left: -2px;
  margin-right: -2px;
  margin-top: -2px;
  cursor: pointer;
}
