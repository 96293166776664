.homepage-body {
  margin-top: -33px;
  width: 100%;
  height: 1131px;
  color: #ffffff;
}

.homepage-body-content {
  align-content: center;
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 10;
}

.homepage-center {
  display: inline-flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.homepage-left-column {
  margin-top: -15px;
  width: 16%;
  min-width: 400px;
  height: 100%;
}
.homepage-faq {
  align-self: center;

  width: 60%;
  height: 100%;
}
.homepage-right-column {
  width: 16%;
  height: 100%;
}

.homepage-spacer {
  width: 4%;
  height: 100%;
}
