.menu-rc-align {
  margin-top: -20px;
  margin-left: 15px;
  height: 100%;
  width: 100%;
}
.menu-rc-image {
  margin: -2px;
  background-image: url('./MenuRightColumn_images/menu_rightCol.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  width: 300px;
}

.menu-rc-item {
  background-size: cover;
  background-repeat: no-repeat;
  float: left;
  margin-left: 16px;
  margin-top: 44px;
  height: 141px;
  width: 268px;
}

.menu-rc-gif {
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: 1s;
}

.menu-rc-gif:hover {
  opacity: 1;
}
