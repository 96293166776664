.faq-position-1 {
  display: inline-block;
  width: 100%;
  height: 1150px;
}

.faq-box {
  color: white;
  /* background-color: #222222; */
  border: #69503b 3px double;
  border-bottom: none;
  margin-top: -50px;
}

.faq-content {
  height: 1048px;
  /* max-height: 1020px; */
  overflow: hidden;
  margin-top: 90px;
  padding-left: 10px;
  padding-right: 20px;
  color: #fefefedb;
}
