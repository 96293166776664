html,
body {
  margin: 0;
  background-color: #111111;
  z-index: -1;
}

h3 {
  text-align: center;
}

h1 {
  margin: 0;
}

input {
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  color: #00c3ffd0;
}
.App {
  text-align: center;
  position: absolute;
  width: 100%;
  min-width: 1650px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.space-from-header {
  padding-top: 100px;
}

.background-site {
  width: 100%;
  height: 100%;
  min-height: 1080px;
  background-image: url('../../components/App/App_public/bg_body.jpg');
  background-repeat: repeat-y;
  background-size: 100%;
  object-fit: cover;
  background-color: #111111;
  z-index: 0;
  position: fixed;
}
