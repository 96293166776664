.header {
  width: 100%;
  height: 708px;
}

.banner-back-image {
  user-select: none;
  height: 600px;
  width: 100%;
  min-width: 1300px;
  z-index: 1;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: black;
  object-fit: fill;
}

.banner-front-image {
  user-select: none;
  width: 100%;
  min-width: 1300px;
  background-color: black;
}

.banner-logo-box {
  z-index: 2;
  min-width: 1300px;
  width: 100%;
  height: 400px;
  margin-top: 150px;
  top: 0;
  position: absolute;
}

.banner-logo-image {
  user-select: none;
  margin-left: auto;
  margin-right: auto;
}

.header-nav-box {
  width: 100%;
  background-image: url('./Header_images/header-menu.png');
  height: 108px;
  z-index: 1000;
}
